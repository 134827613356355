.asolviButton{
    text-align: center !important; 
    display: block !important;
    background-color: white !important;
    font-size: inherit !important;
    color: var(--main-color) !important;
}

.k-button{
    background-color: var(--button-background-color) !important;    
    color: var(--button-color);
}

.k-primary{
    background-color: var(--primary-button-background-color) !important;
    color: var(--primary-button-color) !important;
}

.k-danger {
    background-color: var(--danger-button-background-color) !important;
    color: var(--danger-button-color) !important;
}
