.dates {
    display: flex;
    justify-content: stretch;
}

.fromDate {
    flex: 1;
}

.separator {
    flex: 0;
    margin: 0 1em;
}

.toDate {
    flex: 1;
}