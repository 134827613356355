.taskLogDetailsGroup {
    margin-bottom: 15px;
    width: 800px;
    margin-right: 10px;
    padding: 15px 15px;
    border-style: solid;
    border-width: thin;
    border-color: var(--footer-background-color);
    background-color: white;
    box-shadow: 2px 2px #ececec;
}

#taskLogModal .modal-content {
    background-color: var(--footer-background-color);
}

.task-timeline {
    margin-bottom: 15px;
    width: 500px;
    margin-right: 10px;
    padding: 15px 15px;
    border-style: solid;
    border-width: thin;
    border-color: var(--footer-background-color);
    background-color: white;
    box-shadow: 2px 2px #ececec;
}


.task-flex-container {
    display: flex;
    flex-direction: row;
}

.task-timeline .icon-color-green {
    color: green;
}

.task-timeline .row-layout {
    padding-bottom: 15px;
}

.task-timeline .column-layout {
    padding-left: 36px;
}

.task-timeline .center {
    padding-top: 28px;
}

.task-timeline .center2 {
    padding-top: 18px;
}
