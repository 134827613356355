

.filterSpinner {
    width: 720px;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,.7);
    display: flex;
    justify-content: center;
    z-index: 10003;
}
