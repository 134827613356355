.container {
    display: flex;
    justify-content: stretch;
}

.label {
    flex: 4;
    padding-right: 0.5em;
}

.code {
    flex: 4;
    padding: 0 0.5em;
}

.value {
    flex: 4;
}