@media (min-width: 1200px) {
  div#machineDetailsModal {
    max-width: 1340px;
  }
}

.machineDetailsGroup{    
  margin-bottom: 15px;
  padding: 15px 15px;
  border-style: solid;
  border-width: thin;
  border-color: var(--footer-background-color);
  background-color: white;
  box-shadow: 2px 2px #ececec;
}

#machineDetailsModal .modal-content {
  background-color: var(--footer-background-color);
}