.loginContainer{
    background-color: var(--start-right-side-background-color);
    font-family: "Roboto";
    font-size: 28px;
    height: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.loginLeftCol{
    background-image: linear-gradient(to bottom left, var(--start-left-side-color1), var(--start-left-side-color2), var(--start-left-side-color3));
    color: var(--start-left-side-text-color);
    min-height: 100vh;
    padding-left: 0;    
}
.loginRightCol{
    height: 100%;
    max-height: 100%;
    padding-right: 0 !important;
}
.reindeerLoginImage{
    float: right;
    height: 100%;
    max-height: 100vh;
}
.loginRow{
    padding:0 !important;
    height: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.loginDisplayText{
    padding-top: 35%;
}