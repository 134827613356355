.TaskLogGrid {
    position: relative;
}
.TaskLogGrid :global(.k-grid) {    
    height: calc(100vh - 473px);    
}

.gridOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10002;
}

.taskModal {
    width: 1380px !important;
    max-width: 1380px !important;
}
