.titleRow{
    margin-bottom : 40px;
}
.loginGroupButtonRow{
    margin-top : 100px;
}
.loginGroupButtonColumn{
    margin-bottom: 20px;
}
.customLink {
    color: #85c0ff;
    font-size: 18px;
}
.customLink:hover {
    color: #4da5fd;
}
