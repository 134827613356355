.settingsContainer{
    background-color: var(--start-right-side-background-color);
    font-family: "Roboto";
    font-size: 28px;
    height: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.settingsLeftCol{
    background-image: linear-gradient(to bottom left, var(--start-left-side-color1), var(--start-left-side-color2), var(--start-left-side-color3));
    color: var(--start-left-side-text-color);
    height: 100%;
    max-height: 100%;
    padding-left: 0;    
}
.settingsRightCol{
    height: 100%;
    max-height: 100%;
    padding-right: 0 !important;
}
.settingsReindeerImage{
    float: right;
    height: 100%;
    max-height: 100%;
}
.settingsRow{
    padding:0 !important;
    height: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.settingsDisplayText{
    padding-top: 35%;
}
/********************Kendo UI Overrides***************************************/
/*DropdownList*/
.k-textbox-container{
    width: 100%;
}
.k-textbox-container > .k-label{
    color: var(--start-left-side-text-color);
}
.k-dropdown .k-dropdown-wrap{
    border-color: var(--start-left-side-line-color);
    color: var(--start-left-side-text-color);
}
.k-dropdown .k-dropdown-wrap:hover, .k-textbox-container::after, .k-floating-label-container::after, .k-dropdown .k-dropdown-wrap.k-state-focused{
    border-color: var(--start-left-side-line-focus-color);
}
.k-textbox-container.k-state-focused > .k-label{
    color: var(--start-left-side-text-focus-color) !important;
}