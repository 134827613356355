.OrderLogResultContainer .k-link.k-state-selected{
    color: var(--main-header-text-color) !important;
    background-color: var(--main-color) !important;
}

.OrderLogResultContainer .k-link{
    color: var(--start-left-side-text-focus-color) !important;
    background-color: var(--main-header-text-color) !important;
}

.OrderLogResultContainer .k-state-selected{
    color: var(--main-header-text-color) !important;
    background-color: var(--main-color) !important;
}

.OrderLogResultContainer {
    margin-top: 30px; 
    padding-left: 0px!important;   
}

.OrderLogResultContainer .k-grid {
    line-height: 1;    
}

.OrderLogResultDetailGrid {
    padding-top: 15px;    
}

.OrderLogSumRow {
    margin-top: 15px;
}

.OrderLogOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10004;
}