.gridContainer {
    margin-bottom: 15px;
    position: relative;
}


.gridOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10002;
}