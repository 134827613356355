.MeterReadingOuterContainer{
    width: 100%;
    padding: 21px 54px;
}
.MeterReadingContainer{
    padding: 0 !important;
    min-height: 525px;
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.meterReadingGrid{
    height: 100%;
    min-height: 450px;
}

.primary{
    background-color:  var(--main-color);
}
 
.MeterReadingContainer .buttonRow{
    background-color: white;
    margin-top: 10px;
}

.meterReadingGrid .k-state-invalid {
    border-width: 3px !important;
} 
.k-picker-wrap{
    border-width: 0px !important;
}

.meterReadingTopRow {
    flex: 0;
}

.meterReadingGridRow {
    flex: 1;
}

.meterReadingButtonRow {
    flex: 0;
}

.meterReadingEmptyRow {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}