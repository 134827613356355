.asolviFooter{
    color: var(--footer-color);
    background-color: var(--footer-background-color);
    font-size: 15px;
    width: 100%;
    padding: 20px;
}

.asolviFooter img {
    width: 30px;
    height: 30px;
}