.headingRow {
    margin-bottom: 1em;
}

.footerRow {
    margin: 1em 15px 0 15px;
    display: flex;
    justify-content: flex-end;
}

.spinnerCell {
    position: relative;
}

.spinnerOverlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinnerCellLoading {
    opacity: 0.25;
}