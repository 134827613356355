.OrderFooter {
    padding-top: 20px;
    padding-bottom: 20px;
}

.ButtonRow {
    display: flex;
    justify-content: flex-end;
    margin: 0 15px;
}


.Modal {
    width: fit-content !important;
    max-width: 90vw !important;   
}