.MeterReportingContainer{
    padding: 0 !important;
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    display: block !important;
}

.mainRow{
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 17px;
    background-color:  white;
    display: block;
    min-height: calc(92vh - 60px);
}

.header{
    color:white;
    background-color: var(--main-color);
    font-size: 15px;
    width: 100%;
    height: 60px;
    padding-top: 17px;
}

.footer{
    color: var(--footer-color);
    background-color: var(--footer-background-color);
    min-height: 63px !important;    
    display: block !important;
}

.meterGrid{
    height: 100%;
    min-height: calc(75vh - 60px);
}

.primary{
    background-color:  var(--main-color);
}
 
.MeterReportingContainer .buttonRow{
    background-color: white;
    margin-top: 10px;
}

.meterGrid .k-state-invalid {
    border-width: 3px !important;
} 
.k-picker-wrap{
    border-width: 0px !important;
}