.k-grid-toolbar {
    display: block;
}

.AsolviGrid{
    line-height: 15px !important;
    margin-left: 16px;
    width: 100vw - 15px;
    height:100%;
    max-width: calc(100vw - 150px);
    min-width: 25vw;
    overflow-x: auto;
    overflow-y: hidden;
}

/*.k-drawer-expanded .AsolviGrid {
    max-width: calc(100vw - 385px) !important;
}*/

.AsolviGrid > .k-grid-header{
    line-height: 8px !important;
}

.AsolviGrid > .k-filter-cell{
    line-height: 10px !important;
}

.AsolviGrid th {
    border-style: none !important;
}

.AsolviGrid td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}