.machineSearchGrid{
    line-height: 15px;
    height: 280px !important;
}

.machineSearchGrid > .k-grid-header{
    line-height: 8px !important;
}

.machineSearchGrid > .k-filter-cell{
    line-height: 10px !important;
}

.k-grid th {
    border-style: none !important;
}
