.TaskCreatePageContainer .k-link.k-state-selected{
    color: var(--main-header-text-color) !important;
    background-color: var(--main-color) !important;
}

.TaskCreatePageContainer .k-link{
    color: var(--start-left-side-text-focus-color) !important;
    background-color: var(--main-header-text-color) !important;
}

.TaskCreatePageContainer .k-state-selected{
    color: var(--main-header-text-color) !important;
    background-color: var(--main-color) !important;
}

.TaskFooter {
    padding-top: 20px;
    padding-bottom: 20px;
}
.AsolviRadioGroup {
    padding-top: 20px;
    padding-bottom: 20px;
}