.accepted {
    color: var(--success);
}

.declined {
    color: var(--red);
}

.withdrawn {
    color: rgba(0, 0, 0, 0.50);
}
