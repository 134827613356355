.container {}

.container :global(.k-textarea) > :global(.k-input) {
    padding: 0;
}

.checkboxRow {
    display: flex;
    align-items: center;
    height: 36px;
}

.postContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.headingPostCode {
    min-width: 6ch;
    padding-right: 4px !important;
}

.headingPostLocation {
    padding-left: 4px !important;
}