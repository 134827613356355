.fullHeightContainer {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;
}

.flexRow {
    flex: 1 1;
}

.staticRow {
    flex: 0 0;
}