#orderLogLoader {
    position: fixed;
    overflow: hidden;
    justify-content: center;    
    top: 250px;
    left: calc(50% - 50px)    
  }

.filterGroup{
  width: 100%;
  max-width: 100vw-15px;
  height: 100%;
  padding-bottom: 15px;
}

.orderFilterGroup {
  padding-top: 20px;
  padding-bottom: 10px;
}