.title {
    font-size: 120%;
    text-decoration: underline;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.titleWithdrawn {
    color: rgba(0, 0, 0, 0.50);
    cursor: text;
    text-decoration: none;
 }

.date {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.container {
    display: contents;
    justify-content: space-between;
}

.container:hover {
    
}