.collection {
    display: inline-grid;
    grid-template-columns: auto 1fr auto auto auto;
    align-items: baseline;
    column-gap: 1em;
    row-gap: 0.5em;
    max-width: 100%;
}

.item {
    display: contents;
}

.header {
    font-weight: 600;
}