.machinePageContainer {
    overflow-y: auto;
    width: 100%;
    padding: 0 !important;
    overflow-x: hidden;
}

.buttonRow {
    padding: 1em 0 16px 0;
    display: flex;
    justify-content: space-between;
}

.leftSideButtons {
    display: flex;
    gap: 1em;
}

.rightSideButtons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
}

.searchButton {
    width: auto;
}

.clearButton {
    width: auto;
}

.newMachineButton {
    width: auto;
}